<template>
  <div class="profile">
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
    <Loading
      :loading="loading"
      message="Loading Profile"
    />
    <div v-if="profile">
      <ProfileHeader :profile="profile" />
      <v-row>
        <v-col>
          <v-btn
            :to="{ name: 'MessageSend', query: { to: profile.user } }"
            class="mr-4"
          >
            <v-icon>
              $message
            </v-icon>
          </v-btn>
          <Share
            :title="profile.user"
            class="mr-4"
          />
          <Like
            :object-id="profile.id"
            app="alliance"
            model="profile"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="profile.achievements.length"
          cols="12"
          md="6"
        >
          <AchievementList :achievements="profile.achievements" />
        </v-col>
        <v-col
          v-if="profile.share_evidence"
          cols="12"
          md="6"
        >
          <EvidenceLocker :profile="profile.id" />
        </v-col>
        <v-col
          v-if="profile.recruits.length"
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title>
              Recruits
            </v-card-title>
            <LeaderList
              max-height="450px"
              :entry-list="profile.recruits"
              :load-more="false"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AchievementList from '@/components/AchievementList.vue'
import EvidenceLocker from '@/components/EvidenceLocker.vue'
import LeaderList from '@/components/LeaderList.vue'
import Like from '@/components/Like.vue'
import Loading from '@/components/Loading.vue'
import ProfileHeader from '@/components/ProfileHeader.vue'
import Share from '@/components/Share.vue'

export default {
  components: {
    AchievementList,
    EvidenceLocker,
    LeaderList,
    Like,
    Loading,
    ProfileHeader,
    Share,
  },
  data: function() {
    return {
      profile: null,
      alerts: [],
      loading: false,
    }
  },
  created: function () {
    this.username = this.$route.params.username
    this.getProfile()
  },
  methods: {
    getProfile() {
      this.loading = true
      return this.$store.dispatch('profiles/getDetails', '/alliance/profile/' + this.username + '/')
        .finally(() => {
          this.profile = this.$store.getters['profiles/getDetailsByUsername'](this.username) || null
          this.loading = false
          if (!this.$online && !this.profile) {
            this.alerts.push({
              type: 'error',
              message: 'An internet connection is required to load this profile.',
            })
          } else if (!this.profile) {
            this.alerts.push({
              type: 'error',
              message: 'Failed to load profile.',
            })
          }
        })
    },
  },
}
</script>
